.profile-photo{
    height: 200px; 
    width: 200px;
    object-fit: cover
}

.one-screen{
  height: 77vh;
}

.firebaseui-idp-icon{
  min-height: 30px;
  min-width: 30px;
  /* height: 30px; */
  /* width: 30px; */
}

@media only screen and (max-width: 768px) {
  /*.one-screen{
    height: auto;
  }*/
}