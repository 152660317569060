.custom-card{
    border-radius: 6%;
    overflow: hidden;
    /* width: 16rem; */
    /* height: 50vh; */
    height: 300px;
    width: 200px;
    cursor: pointer;
}

.custom-card-img{
    height: 70%;
    width: 100%;
    object-fit: cover;
}

.custom-card-text{
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
}

.custom-links{
    object-fit: cover;
    width: 18px;
    height: 18px;
}

.card-div{
    margin: auto 1rem;
    padding: 0%;
    max-width: 200px;
}

.card-link+.card-link{
    margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .custom-card {
        height: 150px;
        width: 100px;
    }

    .custom-card-img{
        height: 60%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .custom-card-text{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.7em;
    }

    .card-body{
        --bs-card-spacer-y:0.5rem;
        --bs-card-spacer-x:0.5rem;
    }

    .custom-links{
        width: 13px;
        height: 13px;
    }

    .card-link{
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        /* width: 15px;
        height: 15px; */
        --bs-card-spacer-x: 0.1rem
    }

    .card-link+.card-link{
        margin-left: 0.1rem;
    }
    
    .card-div{
        max-width: 100px;
    }
  }