.custom-navbar{
  background-color: #FEEFDC;
}

.custom-nav > *{
    color: #e35335;
}

.navbar-nav .nav-link.active, .nav-link:hover{
    color: #e35335;
    font-weight: bold;
}

.custom-icons {
    object-fit: contain;
    width: 25px;
    height: 25px;
}

.logo{
  object-fit: contain;
  width: 150px;
  /* height: 25px; */
}
