.review-photo{
    /* width:80%; */
    height:15vh;
    object-fit:contain
}

.review-post-photo{
    max-height:40%;
    max-width:70%;
    object-fit:contain
}

.small-button{
    width:3vw;
    text-align: center;
    font-size: 0.6em;
    color: #6c757d;
    /* color: $secondary; */
     border: solid;
     border-color: #6c757d;
     border-width: 1px;
     border-radius: 10px;
}

.small-button:hover{
    color:white;
    background-color: #6c757d;
}

.as-button{
    cursor: pointer;
}

.as-button:active{
    transform:scale(1.2);
}

.custom-minw-fit{
    min-width: fit-content;
    flex: 1;
}

.summary-icon{
    width: 45px
}

.photo-list{
    height: 100%;
}

@media only screen and (max-width: 768px) {
  .photo-list{
    height: auto;
  }

  .inquiry-r-c-button{
      font-size: 0.7em;
  }
}