html {
  /* padding:0;
  margin:0; */
  background-color: #FEEFDC;
}

.app {
  background-color: #FEEFDC;
}

.body {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .body {
    padding-left: 0px;
    padding-right: 10px;
  }
}

.creator-list {
  margin-bottom: 50px;
}

.creator-link-image{
  height: 25px;
  width: 25px;
  object-fit: fill;
}

.custom-fs-sm{
  font-size: 0.9em;
}

.custom-fs-xs{
  font-size: 0.7em;
}

.custom-fs-xl{
  font-size: 4em;
}

.proper-text{
  white-space: pre-line;
}

.btn-close{
  order: -1;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-0.5deg); }
  20% { transform: translate(-2px, 0px) rotate(0.5deg); }
  30% { transform: translate(2px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(0.5deg); }
  50% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  60% { transform: translate(-2px, 0px) rotate(0deg); }
  70% { transform: translate(2px, 0px) rotate(-0.5deg); }
  80% { transform: translate(1px, 1px) rotate(0.5deg); }
  90% { transform: translate(-1px, -1px) rotate(0deg); }
  100% { transform: translate(1px, 1px) rotate(-0.5deg); }
}

.shake {
  animation: shake 3s ease-in-out; /* Add easing for smoothness */
  animation-iteration-count: infinite; /* Repeat the shake animation infinitely */
}